import React from 'react'
import logo1 from '../../assets/img/logomark/logo1.png'
import logo2 from '../../assets/img/logomark/logo2.png'
import logo3 from '../../assets/img/logomark/logo3.png'
import logo4 from '../../assets/img/logomark/logo4.png'
import logo5 from '../../assets/img/logomark/logo5.png'
import logo6 from '../../assets/img/logomark/logo6.png'
import logo7 from '../../assets/img/logomark/logo7.png'
import logo8 from '../../assets/img/logomark/logo8.png'
import logo9 from '../../assets/img/logomark/logo9.png'
import logo10 from '../../assets/img/logomark/logo10.png'
import logo11 from '../../assets/img/logomark/logo11.png'
import logo12 from '../../assets/img/logomark/logo12.png'
import logo13 from '../../assets/img/logomark/logo13.png'
import logo14 from '../../assets/img/logomark/logo14.png'
import logo15 from '../../assets/img/logomark/logo15.png'
import logo16 from '../../assets/img/logomark/logo16.png'
import logo17 from '../../assets/img/logomark/logo17.png'
import logo18 from '../../assets/img/logomark/logo18.png'
import logo19 from '../../assets/img/logomark/logo19.png'
import logo20 from '../../assets/img/logomark/logo20.png'

const Logos = () => (
  <section className='section has-text-centered container' id='logomarks'>

    <div className='content column is-half is-offset-one-quarter'>
      <p>These are just some of the companies that work closely with Critical Friend Partnership, please feel free to call the Managing Director of any of these to gain an understanding of what it is really like when you work with Critical Friend Partnership.</p>
    </div>

    <div className='level'>

      <div className='level-item'>
        <img className='image' width='100' src={logo1} />
      </div>
      <div className='level-item'>
        <img className='image' width='100' src={logo2} />
      </div>
      <div className='level-item'>
        <img className='image' width='100' src={logo3} />
      </div>
      <div className='level-item'>
        <img className='image' width='100' src={logo4} />
      </div>
      <div className='level-item'>
        <img className='image' width='100' src={logo5} />
      </div>
      <div className='level-item'>
        <img className='image' width='100' src={logo6} />
      </div>

    </div>

    <div className='container level'>

      <div className='level-item'>
        <img className='image' width='100' src={logo7} />
      </div>
      <div className='level-item'>
        <img className='image' width='100' src={logo8} />
      </div>
      <div className='level-item'>
        <img className='image' width='100' src={logo9} />
      </div>
      <div className='level-item'>
        <img className='image' width='100' src={logo10} />
      </div>
      <div className='level-item'>
        <img className='image' width='100' src={logo11} />
      </div>
      <div className='level-item'>
        <img className='image' width='100' src={logo12} />
      </div>
      <div className='level-item'>
        <img className='image' width='100' src={logo13} />
      </div>

    </div>

    <div className='container level'>

      <div className='level-item'>
        <img className='image' width='100' src={logo14} />
      </div>
      <div className='level-item'>
        <img className='image' width='100' src={logo15} />
      </div>
      <div className='level-item'>
        <img className='image' width='100' src={logo16} />
      </div>
      <div className='level-item'>
        <img className='image' width='100' src={logo17} />
      </div>
      <div className='level-item'>
        <img className='image' width='100' src={logo18} />
      </div>
      <div className='level-item'>
        <img className='image' width='100' src={logo19} />
      </div>
      <div className='level-item'>
        <img className='image' width='100' src={logo20} />
      </div>

    </div>

  </section>
)

export default Logos
